import { Component, inject, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactFormService } from "src/app/services/contactForm.service";

@Component({
  selector: "app-landing-contactus-form",
  template: `<form action="" class="right-reveal" [formGroup]="contactForm">
    <div [class]="{ 'submitted-message': true, show: this.submitted }" i18n="LandingContactUsFormSubmittedMessage">
      ¡Enviamos tu consulta! A la brevedad nos contactaremos contigo.
    </div>
    <div i18n="LandingContactUsFormErrorMessage"
      [class]="{ 'submitted-message': true, show: this.formErrors.length > 0 }"
    >
    Por favor ingresa todos los campos. El email debe ser válido.
    </div>
    <p class="contact-title" i18n="LandingContactUsFormTitle">¡Contáctenos!</p>
    <input type="hidden" name="tipo" formControlName="tipo"/>
    <input type="text" placeholder="Nombre" formControlName="nombre" i18n="LandingContactUsFormNameInput"/>
    <input type="email" placeholder="Email" formControlName="email" i18n="LandingContactUsFormEmailInput" />
    <textarea
      i18n="LandingContactUsFormMessage"
      name="Mensaje"
      id="mensaje"
      placeholder="Mensaje"
      formControlName="notas"
    ></textarea>
    <div>
      <button i18n="LandingContactUsFormButtonText" class="button-animated" (click)="submitContactForm()">
        Enviar consulta
      </button>
      <div class="ask-about">
        <p>Se consulta sobre {{formTipo}}</p>
      </div>
    </div>
  </form>`,
  styleUrls: ["./contact-us-form.component.scss"],
})
export class ContactUsFormComponent {
  @Input() formTipo!: string;

  public contactForm = new FormGroup({
    nombre: new FormControl("", Validators.compose([Validators.required])),
    email: new FormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
      ])
    ),
    notas: new FormControl("", Validators.required),
    tipo: new FormControl(this.formTipo, Validators.required),
  });

  loadingForm = false;
  submitted = false;
  formErrors = "";

  constructor(private contactFormService: ContactFormService) {}

  isValid() {
    return (
      this.contactForm.get("nombre").valid &&
      this.contactForm.get("email").valid &&
      this.contactForm.get("notas").valid
    );
  }

  submitContactForm() {
    this.contactForm.controls["tipo"].setValue(this.formTipo);
    this.contactForm.markAllAsTouched();
    this.loadingForm = true;
    console.log(this.isValid());
    if (this.isValid()) {
      this.contactFormService.send(this.contactForm.value).subscribe(() => {
        this.loadingForm = false;
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
        }, 5000);
        this.contactForm.reset();
      });
    } else {
      console.log(this.contactForm.get("nombre").errors);
      console.log(this.contactForm.get("notas").errors);
      console.log(this.contactForm.get("email").errors);
      if (!this.isValid()) {
        this.formErrors =
          "Por favor ingresa todos los campos. El email debe ser válido.";
        setTimeout(() => {
          this.formErrors = "";
        }, 5000);
      }
    }
  }
}
