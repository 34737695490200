import {PlanRecursoService} from './../../services/planRecurso.service';
import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Plan} from '../../models/plan.model';
import {PlanRecurso} from '../../models/planRecurso.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrdenPlanCompraRecurso} from '../../models/ordenplancomprarecurso.model';
import {Observable} from 'rxjs';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@utils';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-complements-list',
  templateUrl: './complements-list.component.html',
  styleUrls: ['./complements-list.component.scss']
})
export class ComplementsListComponent implements OnInit {
  recursosParsed: Array<PlanRecurso[]>;
  recursosFormGroup: FormGroup;
  recursos: OrdenPlanCompraRecurso[] = [];
  recursosAgendables: OrdenPlanCompraRecurso[] = [];
  showErrors = false;

  recursoAgendado: any;

  fechaDesdeAgenda: Date;
  fechaHastaAgenda: Date;
  recursoAgendadoId: string;
  recursoAgendadoNombre: string;
  cantidad: number;

  @Output()
  formCambiado = new EventEmitter();

  @Input()
  plan: Plan;

  @Input()
  pathValue$: Observable<OrdenPlanCompraRecurso[]>;

  @Input()
  plan$: Observable<Plan>;

  @Input()
  compraComplemento = false;

  constructor(private fb: FormBuilder, public dialog: MatDialog,
              public planRecursoService: PlanRecursoService) {
  }

  ngOnInit(): void {
    this.recursosParsed = [];
    let recursosAux: PlanRecurso[] = [];
    this.recursosAgendables = [];
    let nombreAux: string;
    let tipoAux: string;
    // if (this.plan !== undefined && this.plan.recursos && this.plan.recursos.length > 0) {
    //   this.plan.recursos.forEach(recurso => {
    //     if ((recurso.listaNombre === undefined) || (recurso.listaNombre !== nombreAux) || (recurso.tipo !== tipoAux)) {
    //       if (recursosAux.length > 0) {
    //         this.recursosParsed.push(recursosAux);
    //       }
    //       nombreAux = recurso.listaNombre;
    //       tipoAux = recurso.tipo;
    //       recursosAux = [];
    //     }
    //     recursosAux.push(recurso);
    //   });
    //   this.recursosParsed.push(recursosAux);
    //   const controles = {};
    //   this.recursosParsed.forEach(recursosGroup => {
    //     if (this.isRequired(recursosGroup) && recursosGroup[0].tipo === 'ELEMENTO') {
    //       if (this.compraComplemento) {
    //         controles[recursosGroup[0].recurso.id] = [null, [Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
    //       } else {
    //         controles[recursosGroup[0].recurso.id] = [null, [Validators.required, Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
    //
    //       }
    //     } else if (recursosGroup[0].tipo === 'ELEMENTO') {
    //       controles[recursosGroup[0].recurso.id] = [null, [Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
    //     } else if (this.isRequired(recursosGroup) && !this.compraComplemento) {
    //       controles[recursosGroup[0].recurso.id] = [null, Validators.required];
    //     } else {
    //       controles[recursosGroup[0].recurso.id] = [null, Validators.nullValidator];
    //     }
    //
    //   });
    //   this.recursosFormGroup = this.fb.group(controles);
    //   if (this.recursosFormGroup !== undefined) {
    //     this.recursosFormGroup.valueChanges.subscribe(value => {
    //       this.recursos = [];
    //       Object.keys(value).forEach(key => {
    //         if (value[key] === true) {
    //           const keyaux: number = +key;
    //           const recurso: OrdenPlanCompraRecurso = {
    //             recurso: {
    //               id: keyaux,
    //               descripcion: null,
    //               tipo: null,
    //               nombre: null,
    //               agendable: null,
    //               contactoId: null,
    //               factorUnidadMinutos: null,
    //               fechaAgendadoDesde: null,
    //               fechaAgendadoHasta: null,
    //               offsetDiasConsultaAgenda: null
    //             }, cantidad: 1, identificador: null
    //           };
    //           this.recursos.push(recurso);
    //         } else if ((value[key] != null) && (typeof value[key] === 'string')) {
    //           const idAux: number = +value[key];
    //           const recurso: OrdenPlanCompraRecurso = {
    //             recurso: {
    //               id: idAux,
    //               descripcion: null,
    //               tipo: null,
    //               nombre: null,
    //               agendable: null,
    //               contactoId: null,
    //               factorUnidadMinutos: null,
    //               fechaAgendadoDesde: null,
    //               fechaAgendadoHasta: null,
    //               offsetDiasConsultaAgenda: null
    //             }, cantidad: 1, identificador: null
    //           };
    //           this.recursos.push(recurso);
    //         } else if ((value[key] != null) && (value[key] !== false)) {
    //           const keyaux: number = +key;
    //           const recurso: OrdenPlanCompraRecurso = {
    //             recurso: {
    //               id: keyaux,
    //               descripcion: null,
    //               tipo: null,
    //               nombre: null,
    //               agendable: null,
    //               contactoId: null,
    //               factorUnidadMinutos: null,
    //               fechaAgendadoDesde: null,
    //               fechaAgendadoHasta: null,
    //               offsetDiasConsultaAgenda: null
    //             }, cantidad: value[key], identificador: null
    //           };
    //           this.recursos.push(recurso);
    //         }
    //       });
    //     });
    //   }
    // }
    if (this.plan$ !== undefined) {
      this.plan$.subscribe(value => {
        this.plan = value;
        this.recursosParsed = [];
        this.recursos = [];
        recursosAux = [];
        this.recursosAgendables=[];
        let map = new Map<string, PlanRecurso[]>();
        let planRecursoArray = [];

        if (value !== null && value.recursos && value.recursos.length > 0) {
          value.recursos.forEach(recurso => {
            if(!!recurso.listaNombre){
                planRecursoArray = map.get(recurso.listaNombre);
                if(planRecursoArray=== undefined)
                  planRecursoArray = [];
                planRecursoArray.push(recurso);
                map.set(recurso.listaNombre, planRecursoArray);
            }
            else {
              if ((recurso.listaNombre === undefined)) {
                if (recursosAux.length > 0) {
                  this.recursosParsed.push(recursosAux);
                }
                nombreAux = recurso.listaNombre;
                tipoAux = recurso.tipo;
                recursosAux = [];
              }
              recursosAux.push(recurso);
            }
          });
          for(const key of map.keys())
            this.recursosParsed.push(map.get(key));
          if (!!recursosAux && recursosAux.length > 0) {
            this.recursosParsed.push(recursosAux);
          }
          const controles = {};
          this.recursosParsed.forEach(recursosGroup => {
            if (this.isRequired(recursosGroup) && recursosGroup[0].tipo === 'ELEMENTO') {
              if (this.compraComplemento) {
                controles[recursosGroup[0].recurso.id] = [null, [Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
                controles[recursosGroup[0].recurso.id + '-fechaDesde'] = [null, Validators.nullValidator];
                controles[recursosGroup[0].recurso.id + '-fechaHasta'] = [null, Validators.nullValidator];
              } else {
                controles[recursosGroup[0].recurso.id] = [null, [Validators.required, Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
                controles[recursosGroup[0].recurso.id + '-fechaDesde'] = [null, Validators.nullValidator];
                controles[recursosGroup[0].recurso.id + '-fechaHasta'] = [null, Validators.nullValidator];
              }
            } else if (recursosGroup[0].tipo === 'ELEMENTO') {
              controles[recursosGroup[0].recurso.id] = [null, [Validators.max(recursosGroup[0].maximo), Validators.min(recursosGroup[0].minimo)]];
              controles[recursosGroup[0].recurso.id + '-fechaDesde'] = [null, Validators.nullValidator];
              controles[recursosGroup[0].recurso.id + '-fechaHasta'] = [null, Validators.nullValidator];
            } else if (this.isRequired(recursosGroup) && !this.compraComplemento) {
              if(recursosGroup[0].recurso.agendable==null || recursosGroup[0].recurso.agendable=='NO_AGENDABLE')
                controles[recursosGroup[0].recurso.id] = [null, Validators.required];
              else{
                controles[recursosGroup[0].listaNombre] = [null, Validators.required];
                controles[recursosGroup[0].listaNombre + '-fechaDesde'] = [null, Validators.nullValidator];
                controles[recursosGroup[0].listaNombre + '-fechaHasta'] = [null, Validators.nullValidator];
              }
            } else {
              controles[recursosGroup[0].recurso.id] = [null, Validators.nullValidator];
              controles[recursosGroup[0].listaNombre] = [null, Validators.nullValidator];
              controles[recursosGroup[0].listaNombre + '-fechaDesde'] = [null, Validators.nullValidator];
              controles[recursosGroup[0].listaNombre + '-fechaHasta'] = [null, Validators.nullValidator];
            }
            if (recursosGroup[0].tipo === 'ELEMENTO') {
              controles[recursosGroup[0].recurso.id + '-nombre'] = [null, Validators.nullValidator];
            } else {
              controles[recursosGroup[0].listaNombre + '-nombre'] = [null, Validators.nullValidator];
            }
          });
          this.recursosFormGroup = this.fb.group(controles);
          if (this.recursosFormGroup !== undefined) {
            this.recursosFormGroup.valueChanges.subscribe(value => {
              this.recursos = [];
              Object.keys(value).forEach(key => {
                if (value[key] === true) {
                  const keyaux: number = +key;
                  this.checkAddFechaDesdeHasta(key);
                  if (!!keyaux) {
                    const recurso: OrdenPlanCompraRecurso = {
                      recurso: {
                        id: keyaux,
                        descripcion: null,
                        tipo: null,
                        nombre: null,
                        agendable: null,
                        contactoId: null,
                        factorUnidadMinutos: null,
                        fechaAgendadoDesde: null,
                        fechaAgendadoHasta: null,
                        offsetDiasConsultaAgenda: null,
                        displayName: null
                      }, cantidad: 1, identificador: key
                    };
                    this.recursos.push(recurso);
                  }
                } else if ((value[key] != null) && (typeof value[key] === 'string')) {
                  const idAux: number = +value[key];
                  this.checkAddFechaDesdeHasta(key);
                  if (!!idAux) {
                    const recurso: OrdenPlanCompraRecurso = {
                      recurso: {
                        id: idAux,
                        descripcion: null,
                        tipo: null,
                        nombre: null,
                        agendable: null,
                        contactoId: null,
                        factorUnidadMinutos: null,
                        fechaAgendadoDesde:  null,
                        fechaAgendadoHasta: null,
                        offsetDiasConsultaAgenda: null,
                        displayName: null
                      }, cantidad: 1, identificador: key
                    };
                    this.recursos.push(recurso);
                  }
                } else if ((value[key] != null) && (value[key] !== false)) {
                  const keyaux: number = +key;
                  this.checkAddFechaDesdeHasta(key);
                  if (!!keyaux) {
                    const recurso: OrdenPlanCompraRecurso = {
                      recurso: {
                        id: keyaux,
                        descripcion: null,
                        tipo: null,
                        nombre: null,
                        agendable: null,
                        contactoId: null,
                        factorUnidadMinutos: null,
                        fechaAgendadoDesde:  null,
                        fechaAgendadoHasta: null,
                        offsetDiasConsultaAgenda: null,
                        displayName: null
                      }, cantidad: value[key], identificador: key
                    };
                    this.recursos.push(recurso);
                  }
                }
              });
              this.recursos.push(...this.recursosAgendables);
              this.formCambiado.emit(value);
            });
          }
        }
      });
    }
    if (this.pathValue$ !== undefined) {
    this.pathValue$.subscribe(value => {
      const pathValue: any = {};
      if (value != null) {
        value.forEach(resource => {
          let sucess = false;
          for (const planRecursoType of this.recursosParsed) {
            for (const planrecurso of planRecursoType) {
              if (planrecurso.recurso.id === resource.recurso.id) {
                // @ts-ignore
                if (planrecurso.tipo === 'ELEMENTO') {
                  pathValue[planRecursoType[0].recurso.id] = resource.cantidad;
                } else if (planrecurso.tipo === 'CHECKBOX') {
                  pathValue[planRecursoType[0].recurso.id] = true;
                } else {
                  pathValue[planRecursoType[0].recurso.id] = resource.recurso.id.toString();
                }
                sucess = true;
                break;
              }
            }
            if (sucess) {
              break;
            }
          }
        });
      }
      this.recursosFormGroup.patchValue(pathValue);
    });
    }

    this.recursosFormGroup.valueChanges.subscribe(value => {
      this.formCambiado.emit(value);
    });
  }

  selectOption(value: any, position: number) {
    this.recursosFormGroup.controls[position].setValue(value);
  }

  isRequired(recursos: PlanRecurso[]) {
    let res = false;
    recursos.forEach(rec => {
      if (rec.obligatorio) {
        res = true;
      }
    });
    return res;
  }

  isValid() {
    if (this.recursosFormGroup !== undefined) {
      return this.recursosFormGroup.valid;
    }
    return true;
  }

  checkAddFechaDesdeHasta(key: any){
    if(key.includes("fechaDesde") || key.includes("fechaHasta"))
    this.recursos.forEach(rec => {
      if(rec.identificador==key.split("-")[0]){
        if(key.includes("fechaDesde"))
          rec.recurso.fechaAgendadoDesde = new Date(this.recursosFormGroup.controls[key].value);
        if(key.includes("fechaHasta"))
          rec.recurso.fechaAgendadoHasta = new Date(this.recursosFormGroup.controls[key].value);
      }
    });
  }

  sortCollection(planesRecursos: PlanRecurso[]): PlanRecurso[] {
    var sortedArray: PlanRecurso[] = planesRecursos.sort((n1, n2) => {
      if (n1.fechaInicio > n2.fechaInicio) {
        return 1;
      }

      if (n1.fechaInicio < n2.fechaInicio) {
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }

  openDialogScheduleComplement(recursos: PlanRecurso[]) {
    this.recursoAgendado = null;

    let idsRecursos: number[] = [];
    recursos.forEach(rec => {
      idsRecursos.push(rec.recurso.id);
    });

    const dialogRef = this.dialog.open(ScheduleableComplementDialog, {
      width: '85%',
      height: '85%',
      data: {planId: this.plan.id, idsRecursos: idsRecursos, offsetDias: recursos[0].recurso.offsetDiasConsultaAgenda},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.recursoAgendado = result;
      if (this.recursoAgendado != null && this.recursoAgendado != undefined) {
        this.fechaDesdeAgenda = result.agendas[0].fechaInicio;
        this.fechaHastaAgenda = result.agendas[0].fechaFin;
        var cantidadAux = (Math.abs((new Date(this.fechaHastaAgenda)).getTime() - (new Date(this.fechaDesdeAgenda)).getTime()) / 1000 / 60) / result.recurso.factorUnidadMinutos;
        this.recursoAgendadoId = result.recurso.id;
        this.recursoAgendadoNombre = result.recurso.nombre;

        let identificador: any = '';
        if (recursos[0].tipo === 'ELEMENTO') {
          identificador = recursos[0].recurso.id;
        } else {
          identificador = recursos[0].listaNombre;
        }

        if (recursos[0].tipo === 'ELEMENTO') {
          this.recursosFormGroup.controls[identificador].setValue(cantidadAux, {emitEvent: true});
        } else {
          this.recursosFormGroup.controls[identificador].setValue(cantidadAux, {emitEvent: false});
        }
        this.recursosFormGroup.controls[identificador + '-nombre'].setValue(this.recursoAgendado.recurso.nombre, {emitEvent: false});
        this.recursosFormGroup.controls[identificador + '-fechaDesde'].setValue(this.recursoAgendado.fechaInicio.toString().slice(0, 34), {emitEvent: false});
        this.recursosFormGroup.controls[identificador + '-fechaHasta'].setValue(this.recursoAgendado.fechaFin.toString().slice(0, 34), {emitEvent: false});

        if (recursos[0].tipo === 'ELEMENTO') {
          this.recursos.forEach(rec => {
            if(rec.recurso.id==identificador)
            {
              rec.recurso.fechaAgendadoDesde = this.recursoAgendado.fechaInicio;
              rec.recurso.fechaAgendadoHasta = this.recursoAgendado.fechaFin;
            }
          });
        }

        for (var i = this.recursosAgendables.length - 1; i >= 0; --i) {
          if (this.recursosAgendables[i].identificador == identificador) {
            this.recursosAgendables.splice(i, 1);
          }
        }

        if (recursos[0].tipo != 'ELEMENTO') {
          for (var i = this.recursos.length - 1; i >= 0; --i) {
            if (this.recursos[i].identificador == identificador) {
              this.recursos.splice(i, 1);
            }
          }
        }

        const recurso: OrdenPlanCompraRecurso = {
          recurso: {
            id: result.recurso.id,
            descripcion: null,
            tipo: null,
            nombre: null,
            agendable: null,
            contactoId: null,
            factorUnidadMinutos: null,
            fechaAgendadoDesde: this.recursoAgendado.fechaInicio,
            fechaAgendadoHasta: this.recursoAgendado.fechaFin,
            offsetDiasConsultaAgenda: null,
            displayName: null
          },
          cantidad: cantidadAux,
          identificador: identificador
        };

        if (recursos[0].tipo != 'ELEMENTO') {
          this.recursosAgendables.push(recurso);
          this.recursos.push(...this.recursosAgendables);
          this.formCambiado.emit();
        }

      }
    });
  }
}

// begin HTML Demo
@Component({
  selector: 'scheduleable-complement-dialog',
  templateUrl: 'scheduleable-complement-dialog.html',
  styleUrls: ['./complements-list.component.scss']
})
export class ScheduleableComplementDialog implements OnInit {

  busy = false;
  public datesFormGroup: FormGroup;
  public fechaDesde: Date;
  public arrayFechas: Date[] = [];
  public planesRecursos: PlanRecurso[] = [];
  public planesRecursosAux: PlanRecurso[] = [];
  public loading = true;
  public minDate = this.addDays(new Date(), this.dataR['offsetDias']);

  pageIndex = 0;
  pageSize = 10;
  total: number;
  dataTable: any[] = [];

  // begin HTML Demo
  displayedColumns: string[] = ['inicio', 'fin', 'nombre', 'precio', 'acciones'];
  dataSource = new MatTableDataSource<PlanRecurso>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // end HTML Demo

  // begin HTML Demo
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // end HTML Demo
  buscar() {
  }

  public paginar($event: any) {
    this.pageIndex = $event.pageIndex * $event.pageSize;
    this.pageSize = $event.pageSize;
    this.buscar();
  }

  constructor(
    private planRecursoService: PlanRecursoService,
    public dialogRef: MatDialogRef<ScheduleableComplementDialog>,
    @Inject(MAT_DIALOG_DATA) public dataR: any,
    public fb: FormBuilder) {

  }

  ngOnInit(): void {
    //this.obtenerRecursosConAgendas(new Date());
    this.obtenerMenuFechas(this.addDays(new Date(), this.dataR['offsetDias']));
  }

  obtenerRecursosConAgendas(fechaDesde: Date) {
    this.loading = true;
    this.fechaDesde = fechaDesde;
    //this.planRecursoService.getAllWithAgendas(this.dataR["idsRecursos"], this.dataR["planId"], fechaDesde).subscribe(
    //res =>

    let fechaInicial: Date = new Date(fechaDesde);
    this.planesRecursos = [];

    for (var planRecurso of this.planesRecursosAux) {
      for (var agenda of planRecurso.agendas) {
        if ((new Date(agenda.fechaInicio)).toISOString().split('T')[0] == fechaInicial.toISOString().split('T')[0]) {
          let planR: PlanRecurso = {...planRecurso};
          planR.fechaInicio = new Date(agenda.fechaInicio);
          planR.fechaFin = new Date(agenda.fechaFin);
          this.planesRecursos.push(planR);
        }
      }
    }

    //this.planesRecursos = this.sortCollection(this.planesRecursos);

    this.dataSource = new MatTableDataSource<PlanRecurso>(this.planesRecursos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.loading = false;
    // }
    //);
  }

  addDays(date: Date, days: number): Date {
    let fechaAux = new Date(date.getTime());
    fechaAux.setDate(fechaAux.getDate() + days);
    return fechaAux;
  }

  closeDialog(row: any) {
    this.dialogRef.close(row);
  }

  agendar(row: any) {
    this.closeDialog(row);
  }

  obtenerMenuFechas(fechaDesde: Date) {
    this.loading = true;
    this.arrayFechas = [];
    this.fechaDesde = fechaDesde;

    this.planRecursoService.getAllWithAgendas(this.dataR['idsRecursos'], this.dataR['planId'], fechaDesde).subscribe(
      res => {
        this.planesRecursosAux = this.sortCollection(res);

        if ((new Date(this.planesRecursosAux[0].agendas[0].fechaInicio)).toISOString().split('T')[0] != fechaDesde.toISOString().split('T')[0]) {
          this.fechaDesde = new Date(this.planesRecursosAux[0].agendas[0].fechaInicio);
        }

        let fechaInicial: Date = new Date(this.fechaDesde);
        this.planesRecursos = [];

        for (var planRecurso of this.planesRecursosAux) {
          for (var agenda of planRecurso.agendas) {
            if ((new Date(agenda.fechaInicio)).toISOString().split('T')[0] == fechaInicial.toISOString().split('T')[0]) {
              let planR: PlanRecurso = {...planRecurso};
              planR.fechaInicio = new Date(agenda.fechaInicio);
              planR.fechaFin = new Date(agenda.fechaFin);
              this.planesRecursos.push(planR);
            }
          }
        }

        this.dataSource = new MatTableDataSource<PlanRecurso>(this.planesRecursos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        for (var planRecurso of this.planesRecursosAux) {
          for (var agenda of planRecurso.agendas) {
            if (agenda.fechaInicio != null && this.arrayFechas.length == 0) {
              fechaDesde = (new Date(agenda.fechaInicio));
              this.arrayFechas.push(fechaDesde);
            }

            if ((new Date(agenda.fechaInicio)).getDate() != fechaDesde.getDate()) {
              fechaDesde = (new Date(agenda.fechaInicio));

              if (this.arrayFechas.find(x => x.getDate() == fechaDesde.getDate()) == null) {
                this.arrayFechas.push(fechaDesde);
              }


              if (this.arrayFechas.length == 7) {
                this.loading = false;
                return;
              }
            }
          }
        }

        this.loading = false;
      });
  }

  sortCollection(planesRecursos: PlanRecurso[]): PlanRecurso[] {
    var sortedArray: PlanRecurso[] = planesRecursos.sort((n1, n2) => {
      if (n1.fechaInicio > n2.fechaInicio) {
        return 1;
      }

      if (n1.fechaInicio < n2.fechaInicio) {
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }
}

