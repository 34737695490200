<ng-container *ngIf="contrato">
  <div class="appDetailsContent">
    <div class="breadcrumb">
      <!-- appCats -->
      <div class="container">
        <a [routerLink]="'/'" i18n="Home">Home</a>
        <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span
          i18n="Subscriptions">Subscriptions</span>
      </div>
    </div>
  </div>

  <div class="container">
    <mat-card class="order-card-detail mat-elevation-z0 mt-4 mb-4 mt-lg-5 mb-lg-5">
      <mat-card-title-group>
        <img mat-card-image src="{{contrato.plan.servicio.imgUrl}}" alt="{{contrato?.plan?.servicio?.nombre}}">
        <h1 class="mat-typography__underscore">{{contrato.plan.servicio.nombre}}</h1>
      </mat-card-title-group>

      <mat-card-content>
        <button mat-stroked-button color="accent" class="mat-button-xs--block float-right"
          *ngIf="contrato?.estado?.permiteCancelacion" [disabled]="!this.enabledCancelButton" (click)="openDialog()">
          <span i18n="CancelSubLabel">Cancel Subscription</span>
        </button>
        <div class="row">
          <div class="col-12">
            <ul role="list">
              <li role="listitem">
                <span i18n="IdSuscripcionLabel | Etiqueta Id Suscripcion en la card de suscripcion">Contract: </span>
                <span>#{{contrato.idSuscripcionExterno}}</span>
              </li>
              <li role="listitem">
                <span i18n="PlanLabel | Etiqueta Plan en la card de suscripcion">Plan: </span>
                <span>{{contrato.plan.nombre}}</span>
              </li>
              <li role="listitem">
                <span i18n="BillingAccountLabel | Etiqueta Cuenta de facturacion en la card de suscripcion">Billing
                  Account: </span>
                <span>{{contrato.cliente.nombre}}</span>
              </li>
              <li role="listitem">
                <span i18n="ValidFromLabel | Etiqueta Valido Hasta en la card de suscripcion">Valid from: </span>
                <span>{{contrato?.fechaInicioVigencia | date: 'shortDate'}}</span>
              </li>
              <li role="listitem">
                <span i18n="ValidUntilLabel | Etiqueta Valido Desde en la card de suscripcion">Valid until: </span>
                <span>{{contrato?.fechaFinVigencia | date: 'shortDate'}}</span>
              </li>
              <li role="listitem">
                <span i18n="StateLabel | Etiqueta Estado en la card de suscripcion">Status: </span>
                <span>{{contrato.estado.nombre}}</span>
              </li>
            </ul>
          </div>
        </div>
        <button mat-stroked-button color="accent" class="mat-button-xs--block" *ngIf="contrato?.estado?.permiteCancelacion" [disabled]="!this.enabledCancelButton" (click)="openDialog()">
          <span i18n="CancelSubLabel">Cancel Subscription</span>
        </button>

        <a href="{{contrato.plan.servicio.servicioUrl}}" target="_blank" class="url-link mt-4">
          <mat-icon>open_in_new</mat-icon>
          <span i18n="UrlApp | Link de la app">Go to {{contrato.plan.servicio.nombre}}</span>
        </a>
      </mat-card-content>
    </mat-card>

    <mat-tab-group mat-align-tabs="left" class="manageSubscriptionsTabs">
      <mat-tab label="Description">
        <ng-template mat-tab-label>
          <span i18n="DescriptionSubscription | Descripcion de la suscripcion">Description</span>
        </ng-template>

        <div class="row">
          <div class="col-12">
            <h2 class="mat-typography__heading-3 mb-2" i18n="ServiceUrlTitle | Subtitulo url del servicio">App Url</h2>
            <p>
              <span i18n="ServiceUrlText | Texto previo a la url del servicio"> To access the application use the
                following link:</span><br>
              <a href="{{contrato.plan?.servicio?.servicioUrl}}"> {{contrato?.plan?.servicio?.servicioUrl}} </a>
            </p>
            <h2 class="mat-typography__heading-3 mb-2 mt-4" i18n="SuportAppTitle | Subtitulo soborte de la app">Support
              App</h2>
            <div [innerHTML]="contrato?.plan?.servicio?.soporteHtml"></div>
            <h2 class="mat-typography__heading-3 mb-2 mt-4" i18n="ProductInfoAppTitle | Info del productor Titulo">
              Producer Info</h2>
            <div [innerHtml]="contrato?.plan?.servicio?.productor?.soporte_html"></div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Complements" *ngIf="contrato.plan.recursos.length > 0">
        <ng-template mat-tab-label>
          <span i18n="ComplementsSubscription | Complementos de la suscripcion">Add-ons</span>
        </ng-template>

        <div class="d-sm-flex align-sm-center justify-content-sm-between mb-5">
          <h2 class="mat-typography__underscore mb-0" i18n="complementosSus | Complementos">Add-ons</h2>
          <div>
            <button i18n="AgregarComplementos | Agregar complementos" mat-flat-button color="primary"
              class="mat-button-xs--block" aria-label="Add complements" (click)="openPurchaseComponent(contrato)">
              New Add-on
            </button>
          </div>
        </div>

        <div  *ngIf="contrato.complementos.length>0" class="table-container">
          <!-- begin Users Table -->
          <table mat-table [dataSource]="contrato.complementos" matSort matSortActive="complemento" class="mat-elevation-0">
            <ng-container matColumnDef="complemento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="complementoTitulo|Título de listado">Add-on</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.planRecurso.tipo === 'LISTA' || element.planRecurso.tipo === 'LISTA_RADIO_BUTTON'">{{element.planRecurso.listaNombre}} </span>
                <span *ngIf="element.planRecurso.tipo === 'LISTA' || element.planRecurso.tipo === 'LISTA_RADIO_BUTTON'">{{element.planRecurso.recurso.displayName}}</span>
                <span *ngIf="element.planRecurso.tipo === 'ELEMENTO' || element.planRecurso.tipo === 'CHECKBOX'" class="citem__name">
                  {{element.cantidad}} x {{element.planRecurso.recurso.displayName}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef i18n="estadoTitulo|Título de estado">Status</th>
              <td mat-cell *matCellDef="let element">
                {{element.estado}}
                </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef class="cell-xs"></th>
              <td mat-cell class="cell-xs" *matCellDef="let element;">
                <button class="mat-button-md--block mt-3 mt-sm-2 mt-lg-0 ml-lg-4"
                  *ngIf="element.planRecurso.recurso.tipo !== 'CONTINGENTE' && element.estado == 'APROVISIONADO' && contrato?.estado?.permiteCancelacion"
                  mat-stroked-button color="accent" (click)="openDialogCancelComponent(element.id)"
                  i18n="CancelarComplementos | Botón de cancelar complementos">Cancel Add-on</button>
              </td>
            </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsAddons"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsAddons;"></tr>

          </table>
        </div>
        <div i18n="sinComplemento|No hay complementos contratados" *ngIf="contrato.complementos.length==0">
            Suscription without add-ons
        </div>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span i18n="userSubscription | Usuarios">Users</span>
        </ng-template>
        <div class="d-sm-flex align-sm-center justify-content-sm-between mb-5">
          <h2 class="mat-typography__underscore mb-0" i18n="userTitle | Título de pagina de Usuarios">Users</h2>
          <div *ngIf="contrato?.estado.permiteCancelacion">
            <button i18n="botonagreagarUsuario | Agregar Usuario" mat-flat-button color="primary"
              class="mat-button-xs--block" aria-label="New user" (click)="addUserInvitation()">
              New user
            </button>
          </div>
        </div>

        <div class="table-container">
          <!-- begin Users Table -->
          <table mat-table [dataSource]="dataSource" matSort matSortActive="name" class="mat-elevation-0">
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="nombreDetailPage | Nombre"> Name</th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="apellido">
              <th mat-header-cell *matHeaderCellDef i18n="apellidoDetailPage | Apellido"> Last name</th>
              <td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> E-mail</th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="perfil">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="perfileDetailPage | Perfil"> Profile</th>
              <td mat-cell *matCellDef="let element"> {{element?.perfil?.nombre}}</td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="estadoDetailPage | Estado"> Status</th>
              <td mat-cell *matCellDef="let element"> {{element.estado?.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="admin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-sm" i18n="administrarCuentasDetailPage">
                Administrator</th>
              <td mat-cell *matCellDef="let element" class="cell-sm text-center">
                <mat-checkbox  disabled="true" [(ngModel)]="element.admin"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef class="cell-xs"></th>
              <td mat-cell class="cell-xs" *matCellDef="let element; let j = index;">
                <button [disabled]="!element?.borrable" mat-icon-button color="warn" focusable="false"
                  aria-label="Delete user" (click)="deleteUserDialog(element)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
