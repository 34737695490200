<div class="container-fluid text-center sticky-top">
  <div class="row text-center">

    <div class="col-4 d-flex">
      <div>
        <!-- Categories -->
        <app-category-list class="categories-list d-none d-lg-block" tipo="header"
          [categories]="categories"></app-category-list>
      </div>

       <!-- About Us
       <div class="d-none d-lg-block">
        <button routerLink="about-us" mat-button class="btn ml-2">
          <span>About Us</span>
        </button>
      </div>
        Blog
      <div class="d-none d-lg-block">
        <button routerLink="blog" mat-button class="btn ml-2">
          <span>Blog</span>
        </button>
      </div>
       -->

      <div>
        <!-- Busqueda -->
        <button mat-button *ngIf="this.searchBoxService.getValue()" (click)="openDialog()">
          <mat-icon color="primary" class="mr-2">search</mat-icon>
          <span class="d-none d-md-inline"
            i18n="SearchBox | Icono del header en el cual se tiene acceso a la busqueda">Search for solutions</span>
        </button>
      </div>

      <div>
        <!-- About us -->
        <button mat-button [routerLink]="'/about-us'">
          <span class="d-none d-md-inline" i18n="About us">About us</span>
        </button>
      </div>
      <div>
        <!-- Blog -->
        <button mat-button [routerLink]="'/blog'">
          <span class="d-none d-md-inline">Blog</span>
        </button>
      </div>
    </div>


    <div class="col-4 d-flex justify-content-center">
      <!-- Logo -->
      <a [routerLink]="'/'" class="logo-brand">
        <img alt="mytreat" src="{{logo}}" />
      </a>
    </div>


    <div class="col-4 d-flex align-self-center justify-content-end">


      <!-- User not Logged -->
      <ng-container *ngIf="!user">
        <div class="user-toolbar__nologged">
          <div class="d-none d-lg-block h-100">
            <!-- <button (click)="register()" type="button" class="btn btn-register">Join us</button> -->
            <button mat-button (click)="login()" class="btn ml-4">
              <!-- <span i18n="ButtonLogin/Register | El boton de login en el header">Login/Register</span> -->
              <span i18n="ButtonLogin | El boton de login en el header">Login</span>
            </button>
          </div>
        </div>
      </ng-container>

      <!-- User Logged -->
      <ng-container *ngIf="user">
        <div class="user-toolbar__logged d-none d-lg-flex">
          <!-- <div>
            <button mat-button (click)="openAthenaeums()">
              <mat-icon color="primary">apps</mat-icon>
              My athenaeums
            </button>
          </div> -->
          <div>
            <button mat-button [matMenuTriggerFor]="menu1">
              <mat-icon color="primary" class="mr-2">settings</mat-icon>
              <span
                i18n="Administrar Icon| Icono del menu del header en el cual se abre el menu de administrar">Manage</span>
            </button>
            <mat-menu #menu1="matMenu" class="mat-menu-user">
              <a routerLink="/subscriptions">
                <mat-icon color="primary" class="mr-2">shop</mat-icon><span
                  i18n="Suscripciones Icon| Icono del menu del header en el cual se tiene acceso a las suscripciones">Purchases</span></a>
              <a routerLink="ordenes/servicios">
                <mat-icon color="primary" class="mr-2">dynamic_feed</mat-icon><span
                  i18n="Ordenes de Servicio Icon| Icono del menu del header en el cual se tiene acceso a las ordenes de servicio">Orders</span></a>
              <a routerLink="invoice">
                <mat-icon color="primary" class="mr-2">receipt</mat-icon><span
                  i18n="Facturas Icon| Icono del menu del header en el cual se tiene acceso a las facturas">Invoices</span></a>
              <a routerLink="payments">
                <mat-icon color="primary" class="mr-2">paid</mat-icon><span
                  i18n="Pagos Icon| Icono del menu del header en el cual se tiene acceso a los pagos">Payments</span></a>
            </mat-menu>
          </div>
          <div>
            <button mat-button [matMenuTriggerFor]="menu2">
              <mat-icon color="primary" class="mr-2">person</mat-icon>
              <span>{{user.firstName}} {{user.lastName}}</span>
            </button>
            <mat-menu #menu2="matMenu" class="mat-menu-user">
              <a href="user"
                i18n="UserData | Icono del menu del header en  el cual se tiene acceso a los datos de usuario">
                <mat-icon color="primary" class="mr-2">person</mat-icon>
                User data
              </a>
              <a href="#">
                <mat-icon color="primary" class="mr-2">lock</mat-icon>
                Change password
              </a>
              <a (click)="logOut()" i18n="Salir | Icono del menu del header en  el cual se hace Logout">
                <mat-icon color="primary" class="mr-2">exit_to_app</mat-icon>
                Logout
              </a>
            </mat-menu>
          </div>
          <div>
            <button mat-button [matMenuTriggerFor]="menu3">
              <mat-icon [matBadge]="badgeContent" matBadgePosition="above after" id="badgeContent"
                matBadgeColor="primary" matBadgeSize="small">
                notifications
              </mat-icon>
            </button>
            <mat-menu #menu3="matMenu" xPosition="before" class="mat-menu-notification">
              <!-- <div class="notification-title" i18n="notifications | notifications">notifications</div> -->
              <ng-container *ngFor="let notification of notifications" class="notification-container">
                <mat-list role="list" class="list-notifications">
                  <!-- Notificación nueva -->
                  <mat-list-item role="listitem" class="notification-item notification-item--new">
                    <mat-icon mat-list-icon color="primary">{{ notification.tipoId }}</mat-icon>
                    <div matLine>
                      <a href="" [matMenuTriggerFor]="menu3" #matMenu3Trigger="matMenuTrigger"
                        (click)="matMenu3Trigger.closeMenu();openNotification(notification)">
                        {{notification.titulo | limitTo: '25' }}
                      </a>
                    </div>
                    <div class="list-actions">
                      <!-- Marcar como leído -->
                      <ng-template [ngIf]="!notification.leido">
                        <a href="#" mat-icon-button aria-label="Marcar como leída"
                          (click)="readNotificationCheck(notification, true)">
                          <mat-icon>mark_email_unread</mat-icon>
                        </a>
                      </ng-template>
                      <!-- Marcar como no leído -->
                      <ng-template [ngIf]="notification.leido">
                        <a href="#" mat-icon-button aria-label="Marcar como no leída"
                          (click)="readNotificationCheck(notification, false)">
                          <mat-icon>mark_email_read</mat-icon>
                        </a>
                      </ng-template>
                      <!-- Ocultar -->
                      <a href="#" mat-icon-button aria-label="Ocultar notificación"
                        (click)="hideNotificationCheck(notification, true)">
                        <mat-icon>clear</mat-icon>
                      </a>
                    </div>
                  </mat-list-item>
                </mat-list>
              </ng-container>
              <a mat-menu-item href="notifications" class="muted">View all
                Notifications</a>
            </mat-menu>
          </div>
        </div>
      </ng-container>

      <div class="d-none d-lg-block">
        <button mat-button class="language-cta" [matMenuTriggerFor]="language">
          <span *ngIf="IsEnglish"><img src="assets/images/flags/US.png" alt="English" /></span>
          <span *ngIf="!IsEnglish"><img src="assets/images/flags/ES.png" alt="Español" /></span>
        </button>
        <mat-menu #language="matMenu" class="mat-menu-language">
          <a mat-button mat-icon *ngIf="IsEnglish" (click)="changeLanguage('es')"><img src="assets/images/flags/ES.png"
              alt="" /> Español</a>
          <a mat-button mat-icon *ngIf="!IsEnglish" (click)="changeLanguage('en')"><img src="assets/images/flags/UK.png"
              alt="" /> Inglés</a>
        </mat-menu>
      </div>


      <button mat-icon-button (click)="onToggleSidenav()" class="d-lg-none">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>

</div>


<!-- <div class="header-nav">
  <mat-toolbar>
    <mat-toolbar-row class="user-toolbar">

    </mat-toolbar-row>
  </mat-toolbar>
</div> -->
