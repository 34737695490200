import {Component, OnInit} from '@angular/core';
import { PlatformLocation } from "@angular/common";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: environment.multisite ? '/src/multisite/' + environment.multisite + '/layouts/footer/footer.component.html' : './footer.component.html',
  //templateUrl: '/src/multisite/miawellness/layouts/footer/footer.component.html',
  styleUrls: environment.multisite ? ['/src/multisite/' + environment.multisite + '/layouts/footer/footer.component.scss'] :[]
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();

  IsEnglish = false;
  public email = environment.email;
  constructor(
    private platformLocation: PlatformLocation,
  ) {
  }

  ngOnInit(): void {
    const url = this.platformLocation.href;
    const base = environment.urlApp;
    const lang = url.substr(base.length + url.indexOf(base), 2);
    if (lang === "es") {
      this.IsEnglish = false;
    } else {
      this.IsEnglish = true;
    }
  }

  // public categories$: Observable<Category[]>;
  // public categories: Category[];

  // constructor(private categoryService: CategoryService,
  //             private loginService: LoginService) {
  // }

  // ngOnInit(): void {
  //   this.categories$ = this.categoryService.getAll$();
  // }


  // login() {
  //   this.loginService.login();
  // }

  // register() {
  //   this.loginService.login();
  // }

  changeLanguage(language: string) {
    // localStorage.setItem('locale', language);
    window.location.href = environment.urlApp + language;
  }
}
