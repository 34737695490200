<footer *ngIf="IsEnglish">
  <div class="footer__links">
    <div class="container">
      <div class="row pt-3">
        <div class="col-12 py-4">
          <img src="assets/images/logo-mytreats.svg" class="footer_logo" />
        </div>
        <div class="col-xs-12 col-md-6 col-xl-6 pb-5">
          We are an innovative platform dedicated to well-being, lifestyle, and
          health tourism. We offer top-notch services with personalized
          attention (concierge services), covering everything from preventive,
          spas and aesthetics to sports, eco-tourism and wellness services,
          including yoga and meditation. We are committed to providing an
          unparalleled experience for tourists and travelers when choosing and
          enjoying a healthy vacation, taking care of their well-being at an
          individual, family or group level. The team of professionals
          associated with Mytreat ensures the quality and excellence of the
          service, making us the first and only humanized digital platform for
          wellness services.
        </div>
        <div class="d-none d-xl-block col-xl-2"></div>

        <div class="col-xs-12 col-md-6 col-xl-4">
          <div class="row icon_footer">
            <div class="col-8">
              <p class="title">GET IN TOUCH</p>

              <div>
                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-map-marker"></i>
                  <p>
                    <span
                      >2500 Parkview Drive # 1219, Hallandale Beach, FL 33009
                      US.</span
                    >
                  </p>
                </div>

                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-phone"></i>
                  <p>+1 786 346 3592</p>
                </div>

                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-envelope"></i>
                  <p>
                    <a href="mailto:info@mytreat.health">info@mytreat.health</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4">
              <!-- <p class="title">LANGUAGE</p>
                <p>
                  <a (click)="changeLanguage('es')"><img src="assets/images/flags/ES.png" alt="" class="pr-2"/>Español</a>
                  <span class="title" *ngIf="!IsEnglish"><img src="assets/images/flags/ES.png" alt="" class="pr-2" />Español sin link</span>
                </p>
                <p>
                  <a (click)="changeLanguage('en')"><img src="assets/images/flags/US.png" alt="" class="pr-2" />English</a>
                    <span class="title" *ngIf="IsEnglish"><img src="assets/images/flags/US.png" alt="" class="pr-2" />English sin link</span>
                </p> -->
              <p class="title">POWERED BY</p>
              <p>
                <img
                  src="assets/images/logo-aleph-inv.svg"
                  class="footer_logo_aleph"
                  alt="Powered by Aleph"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 footer-copyright">
        <div class="row">
          <div class="col-md-6 col-12">
            <span>Copyright ©mytreat. All rights reserved 2024.</span>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <span
              ><a href="privacy">Privacy Policy</a>&nbsp;|&nbsp;<a
                href="disclaimer"
                >Disclaimer</a
              >&nbsp;|&nbsp;<a href="terms">Terms & conditions</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<footer *ngIf="!IsEnglish">
  <div class="footer__links">
    <div class="container">
      <div class="row pt-3">
        <div class="col-12 py-4">
          <img src="assets/images/logo-mytreats.svg" class="footer_logo" />
        </div>
        <div class="col-xs-12 col-md-6 col-xl-6 pb-5">
          Somos una innovadora plataforma dedicada al bienestar, estilo de vida
          y turismo de salud. Ofrecemos servicios de primer nivel con atención
          personalizada (servicios de conserjería), que abarcan desde medicina
          preventiva, spas y estética, deportes, eco-turismo, servicios de
          bienestar hasta yoga y meditación. Nos interesa brindar una
          experiencia sin igual a turistas y viajeros al momento de elegir y
          disfrutar de unas vacaciones saludables, cuidar de su salud ya sea a
          nivel individual, como familiar o grupal. El equipo de profesionales
          asociados a Mytreat aseguran la calidad y excelencia del servicio,
          transformándola en la primera y única plataforma digital humanizada de
          servicios de bienestar.
        </div>
        <div class="d-none d-xl-block col-xl-2"></div>

        <div class="col-xs-12 col-md-6 col-xl-4">
          <div class="row icon_footer">
            <div class="col-8">
              <p class="title">CONTÁCTENOS</p>

              <div>
                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-map-marker"></i>
                  <p>
                    <span
                      >2500 Parkview Drive # 1219, Hallandale Beach, FL 33009
                      US.</span
                    >
                  </p>
                </div>

                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-phone"></i>
                  <p>+1 786 346 3592</p>
                </div>

                <div class="d-inline-flex align-items-baseline w-100">
                  <i class="fa fa-envelope"></i>
                  <p>
                    <a href="mailto:info@mytreat.health">info@mytreat.health</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4">
              <!-- <p class="title">LANGUAGE</p>
                <p>
                  <a (click)="changeLanguage('es')"><img src="assets/images/flags/ES.png" alt="" class="pr-2"/>Español</a>
                  <span class="title" *ngIf="!IsEnglish"><img src="assets/images/flags/ES.png" alt="" class="pr-2" />Español sin link</span>
                </p>
                <p>
                  <a (click)="changeLanguage('en')"><img src="assets/images/flags/US.png" alt="" class="pr-2" />English</a>
                    <span class="title" *ngIf="IsEnglish"><img src="assets/images/flags/US.png" alt="" class="pr-2" />English sin link</span>
                </p> -->
              <p class="title">POWERED BY</p>
              <p>
                <img
                  src="assets/images/logo-aleph-inv.svg"
                  class="footer_logo_aleph"
                  alt="Powered by Aleph"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 footer-copyright">
        <div class="row">
          <div class="col-md-6 col-12">
            <span>Copyright ©mytreat. Todos los derechos reservados {{currentYear}}.</span>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <span
              ><a href="privacy">Politica de privacidad</a>&nbsp;|&nbsp;<a
                href="disclaimer"
                >Disclaimer</a
              >&nbsp;|&nbsp;<a href="terms">Términos y condiciones</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
